import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogCardHome from '../components/blogCardHome'
import HomeHeader from '../components/homeHeader'
import Loader from '../components/loader'
import ComingSoon from '../components/comingSoon'

import coursesBackgroundMobile from '../../static/coursesMobileBackground.jpg'
import courseBackgroundDesktop from '../../static/newCoursesBackground.jpg'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mobile: {
    [theme.breakpoints.up('lg')]: {
      display: 'none'
    },
  },
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
})
)

const CourseIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  const [loadingIndicator, setLoadingIndicator] = useState(true)

  const classes = useStyles()

  return (
    <div>
      {loadingIndicator &&
        <Loader load={loadingIndicator} setLoad={setLoadingIndicator} />
      }
      <div style={
        {
          display: loadingIndicator ? 'none' : 'block'
        }
      }>
        {/* <ComingSoon /> */}
        <div style={{ alignItems: 'center' }}>

          <div className={classes.desktop} >
            <HomeHeader pic={courseBackgroundDesktop} text='' />
          </div>
          <div className={classes.mobile} >
            <HomeHeader pic={coursesBackgroundMobile} text='' />
          </div>
          <Layout location={location} title={siteTitle}>
            <SEO title="Courses" />
            <BlogCardHome posts={posts} />
          </Layout>
        </div>
      </div>
    </div>
  )
}

export default CourseIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: {
      frontmatter: {tags: {eq: "course"}}
    }sort: {order: DESC, fields: [frontmatter___date]}) {
    edges {
      node {
        excerpt
        fields {
          slug
          readingTime {
            text
          }
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          link
          buttonText
          image: featured {
            childImageSharp {
              resize(width: 1200) {
                src
                height
                width
              }
            }
          }
          tags
        }
      }
    }
  }
}
`
